.clear-icon {
    display: flex;
    position: absolute;
    right: 32px;
    top: 20px;
    color: #717C9C;
}
.line {
    height: 1px;
    background-color: #7782A0;
    margin-top: 16px;
    margin-left: 13px;
    width: 192px;
}

@media only screen and (max-width: 767px) {
    .navheader {
        margin-top: 15px;
        margin-left: 16px;
        text-decoration-line: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
    }
    .navheader .list-item {
        margin-left: 0px;
    }
}